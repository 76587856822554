import "./styles.scss"

import React from "react"

import Button from "components/Button"
import { ApartmentsComparison, InvestmentsComparison } from "./components"

import { useComparedInvestments } from "context/investments-comparison"
import { useComparedApartments } from "context/apartments-comparison"

const Content = ({ type, setType }) => {
  const isBrowser = typeof window !== "undefined"
  const { compared_investments } = useComparedInvestments()
  const { compared_apartments } = useComparedApartments()

  return (
    <section className="comparison-content">
      <div className="container-fluid">
        <div className="comparison-content__switch">
          <button
            onClick={() => setType("investment")}
            className={type === "investment" ? "current" : ""}
          >
            Inwestycje
          </button>
          <button
            onClick={() => setType("apartment")}
            className={type === "apartment" ? "current" : ""}
          >
            Apartamenty
          </button>
        </div>

        {type === "investment" ? (
          <InvestmentsComparison data={compared_investments} />
        ) : (
          <ApartmentsComparison data={compared_apartments} />
        )}

        <div className="comparison-content__cta">
          <Button onClick={() => isBrowser && window.print()}>
            Drukuj porównanie
          </Button>
        </div>
      </div>
    </section>
  )
}

export default Content
