import React from "react"
import { Link } from "gatsby"
import Tippy from "@tippyjs/react"

import FavoriteCompare from "components/FavoriteCompare"

const InvestmentItem = ({ item }) => {
  const tooltipData = data => {
    return <span className="tooltip-convenient">{data}</span>
  }

  return (
    <div className="comparison-content__item comparison-content__item-investment">
      <div className="investment-tile-grid__image-wrapper">
        <div
          className="investment-tile-grid__image"
          style={{
            backgroundImage: `url('${
              item?.acfInvestment?.images?.length > 0 &&
              item?.acfInvestment?.images[0]?.sourceUrl
            }')`,
          }}
        >
          {item?.acfInvestment?.images?.length > 0 && (
            <img src={item?.acfInvestment?.images[0]?.sourceUrl} alt="" />
          )}
        </div>
        <div className="investment-tile-grid__image-logo">
          <img src={item?.acfInvestment?.logo?.localFile?.publicURL} alt="" />
        </div>
        <FavoriteCompare id={item?.id} type="investment" variant="white" />
      </div>
      <div className="comparison-content__item-list">
        <Link to={item?.uri}>
          <h3>{item?.title}</h3>
        </Link>
        <small>Liczba apartamentów</small>
        <p>
          {item?.acfInvestment?.apartmensNumber
            ? item?.acfInvestment?.apartmensNumber
            : "-"}
        </p>
        <small>Metraże</small>
        <p>
          {item?.acfInvestment?.areaNumberMin
            ? item?.acfInvestment?.areaNumberMin
            : "-"}
          m<sup>2</sup>-
          {item?.acfInvestment?.areaNumberMax
            ? item?.acfInvestment?.areaNumberMax
            : ""}
          m<sup>2</sup>
        </p>
        <small>Cena apartamentów brutto od</small>
        <p>
          {item?.acfInvestment?.apartmenPriceMin
            ? item?.acfInvestment?.apartmenPriceMin.toLocaleString()
            : "-"}{" "}
          zł
        </p>
        <small>Lokalizacja</small>
        <p>{item?.acfInvestment?.address}</p>
        <small>Status</small>
        <p>
          {item?.acfInvestment?.status === "sell"
            ? "w sprzedaży"
            : "zrealizowane"}
        </p>
        <small>Planowana data zakończenia budowy</small>
        <p>
          {item?.acfInvestment?.planowanaDataZakonczeniaBudowy
            ? item?.acfInvestment?.planowanaDataZakonczeniaBudowy
            : "-"}
        </p>
        <small>Opcja wykończenia apartamenu pod klucz</small>
        <p>
          {item?.acfInvestment?.opcjaWykonczeniaApartamenuPodKlucz
            ? "Tak"
            : "Nie"}
        </p>
        <small>Miejsce postojowe</small>
        <p>{item?.acfInvestment?.miejscePostojowe ? "Tak" : "Nie"}</p>
        <small>Możliwość zwrotu VAT</small>
        <p>{item?.acfInvestment?.mozliwoscZwrotuVat ? "Tak" : "Nie"}</p>
        <small>Wpis do księgi wieczystej</small>
        <p>{item?.acfInvestment?.wpisDoKsiegiWieczystej ? "Tak" : "Nie"}</p>
        <small>Model biznesowy</small>
        <p>{item?.acfInvestment?.modelBiznesowy ? "Tak" : "Nie"}</p>
        <small className="convienients">Dodatkowe ugodognienia</small>
        <p className="convienients">
          <div className="comparison-content__item-convenients">
            {item?.acfInvestment?.convenientsContent?.map((item, index) => (
              <div key={index}>
                <Tippy
                  content={tooltipData(item.header)}
                  trigger="mouseenter focus"
                  placement="top"
                >
                  <img src={item?.icon?.localFile?.publicURL} alt="" />
                </Tippy>
              </div>
            ))}
          </div>
        </p>
        <small>Region inwestycji</small>
        <p>
          {item?.acfInvestment?.region === "mountains"
            ? "góry"
            : item?.acfInvestment?.region === "sea"
            ? "morze"
            : "warmia i mazury"}
        </p>
      </div>
    </div>
  )
}

export default InvestmentItem
