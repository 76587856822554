import React from "react"
import Slider from "react-slick"
import { Link } from "gatsby"

import FavoriteCompare from "components/FavoriteCompare"

const ApartmentsComparison = ({ data }) => {
  const slider = {
    dots: false,
    arrows: true,
    infinite: true,
    speed: 750,
    autoplay: false,
    slidesToShow: data?.length === 1 ? 1 : 2,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  }

  return data.length > 0 ? (
    <div className="row">
      <div className="col-xl-2 col-sm-3">
        <div className="comparison-content__list">
          <strong>
            Liczba apartamentów do porównania: <span>{data?.length}</span>
          </strong>
          <p>Nazwa projektu</p>
          <p>Region inwestycji</p>
          <p>Lokalizacja</p>
          <p>Metraż (m2)</p>
          <p>Piętro</p>
          <p>Pokoje</p>
          <p>Status</p>
        </div>
      </div>

      <div className="col-xl-8 col-sm-9">
        <Slider {...slider}>
          {data.map((item, index) => (
            <div key={index}>
              <div className="comparison-content__item comparison-content__item-apartment">
                <div className="apartment-tile__image">
                  <img
                    className="apartment-tile__image-view"
                    src={
                      item?.acfApartment?.planLink
                        ? item?.acfApartment?.planLink
                        : item?.acfApartment?.visualization2d?.localFile
                            ?.publicURL
                    }
                    alt=""
                  />
                  {!item?.acfApartment?.isStandalone && (
                    <img
                      className="apartment-tile__logo"
                      src={
                        item?.acfApartment?.investment[0]?.acfInvestment?.logo
                          ?.localFile?.publicURL
                      }
                      alt=""
                    />
                  )}
                  <FavoriteCompare id={item?.id} type={"apartment"} />
                  <div className="apartment-tile__hover">Zobacz</div>
                </div>
                <div className="comparison-content__item-list">
                  <Link
                    to={`${
                      item?.acfApartment?.isStandalone
                        ? `/apartamenty/${item.slug}/`
                        : item?.acfApartment?.investment[0]?.uri
                    }${
                      !item?.acfApartment?.isStandalone ? `${item?.slug}/` : ""
                    }`}
                  >
                    <h3>
                      {item?.acfApartment?.localNumber
                        ? item?.acfApartment?.localNumber
                        : item?.title}
                    </h3>
                  </Link>
                  <p>
                    <span>
                      {item?.acfApartment?.isStandalone
                        ? "-"
                        : item?.acfApartment?.investment[0]?.title}
                    </span>
                  </p>
                  <small>Region inwestycji</small>
                  <p>
                    {item?.acfApartment?.isStandalone
                      ? "-"
                      : item?.acfApartment?.investment[0]?.acfInvestment
                          ?.region === "mountains"
                      ? "góry"
                      : item?.acfApartment?.investment[0]?.acfInvestment
                          ?.region === "sea"
                      ? "morze"
                      : "warmia i mazury"}
                  </p>
                  <small>Lokalizacja</small>
                  <p>
                    {item?.acfApartment?.isStandalone
                      ? item?.acfApartment?.address
                      : item?.acfApartment?.investment[0]?.acfInvestment
                          ?.address}
                  </p>
                  <small>Metraż (m2)</small>
                  <p>
                    {item?.acfApartment?.area}m<sup>2</sup>
                  </p>
                  <small>Piętro</small>
                  <p>
                    {item?.acfApartment?.floor === null
                      ? 0
                      : item?.acfApartment?.floor}
                  </p>
                  <small>Pokoje</small>
                  <p>{item?.acfApartment?.roomsnumber}</p>
                  <small>Status</small>
                  <p>
                    {item?.acfApartment?.status === "available"
                      ? "wolne"
                      : item?.acfApartment?.status === "reserved"
                      ? "zarezerwowane"
                      : "sprzedane"}
                  </p>
                </div>
              </div>
            </div>
          ))}
        </Slider>
      </div>

      <div className="col-xl-2">
        <Link to="/wyszukiwarka/" className="comparison-content__cta-add">
          Dodaj
        </Link>
      </div>
    </div>
  ) : (
    <p className="comparison-content__info">Brak pozycji do wyświetlenia..</p>
  )
}

export default ApartmentsComparison
