import React from "react"
import Slider from "react-slick"
import { Link } from "gatsby"

import InvestmentItem from "./components/InvestmentItem"

const InvestmentsComparison = ({ data }) => {
  const slider = {
    dots: false,
    arrows: true,
    infinite: true,
    speed: 750,
    autoplay: false,
    slidesToShow: data?.length === 1 ? 1 : 2,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  }

  return data.length > 0 ? (
    <div className="row">
      <div className="col-xl-2 col-sm-3">
        <div className="comparison-content__list comparison-content__list--investment">
          <strong>
            Liczba inwestycji do porównania: <span>{data?.length}</span>
          </strong>
          <p>Liczba apartamentów</p>
          <p>Metraże</p>
          <p>Cena apartamentów brutto od</p>
          <p>Lokalizacja</p>
          <p>Status</p>
          <p>Planowana data zakończenia budowy</p>
          <p>Opcja wykończenia apartamenu pod klucz</p>
          <p>Miejsce postojowe</p>
          <p>Możliwość zwrotu VAT</p>
          <p>Wpis do księgi wieczystej</p>
          <p>Model biznesowy</p>
          <p className="convienients">Dodatkowe ugodognienia</p>
          <p>Region inwestycji</p>
        </div>
      </div>

      <div className="col-xl-8 col-sm-9">
        <Slider {...slider}>
          {data.map((item, index) => (
            <div key={index}>
              <InvestmentItem item={item} />
            </div>
          ))}
        </Slider>
      </div>

      <div className="col-xl-2">
        <Link to="/wyszukiwarka/" className="comparison-content__cta-add">
          Dodaj
        </Link>
      </div>
    </div>
  ) : (
    <p className="comparison-content__info">Brak pozycji do wyświetlenia..</p>
  )
}

export default InvestmentsComparison
